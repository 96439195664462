<template>
  <section class="lib-inner-section">
    <div class="container position-relative">
      <button id="advance-filter-btn" @click="toggleFilter" class="btn btn-advance-toggle-filter bg-transparent position-absolute p-0 color-darkgrey fw-500 btn-no-outline d-flex align-items-center" :class="{'collapsed': !toggle}" data-target="#advance-filter" aria-controls="advance-filter">
        <img v-if="!toggle" src="~/assets/icons/library/settings.svg" height="26px" class="setting-icon" title="Hiển thị bộ lọc" alt="" />
        <img v-else src="~/assets/icons/library/close-dark.svg" height="26px" class="close-icon" title="Ẩn bộ lọc" alt="" />
        <span class="d-none d-lg-block">Bộ lọc</span>
      </button>
      <div class="pt-2 pt-lg-3 d-flex">
        <MenuLeft :show="toggle" />
        <div class="list-data" :class="{'w-100': !toggle}" id="list-data">
          <FormSearch />
          <div class="data-block my-4 position-relative p-0 bg-white">
            <div class="px-2 px-lg-4 py-2 py-lg-4">
              <p class="data-block-counter text-primary" v-if="query.keyword">{{ query.total }} kết quả tìm kiếm cho “<span>{{query.keyword}}</span>”</p>
              <div class="data-divider" v-if="query.keyword"></div>
              <div v-if="query.tab === 'TOPIC'">
                <div v-if="loading" class="list-cards row mt-3 mt-lg-4">
                  <SkeletonTopicItem v-for="ind in 4" :key="ind" class="col-12 mb-3 mb-lg-4" :class="{'col-12 col-sm-3': !toggle, 'col-lg-4': toggle}"/>
                </div>
                <div v-else class="list-cards row mt-3 mt-lg-4">
                  <template v-if="query.type === 'topic'">
                    <TopicItem v-for="(item, ind) in listData" :key="ind" class="col-12 mb-3 mb-lg-4" :class="{'col-lg-3': !toggle, 'col-lg-4': toggle}" :item="item"/>
                  </template>
                  <template v-if="query.type === 'video'">
                    <VideoItem v-for="(item, ind) in listData" :key="ind" class="col-12 mb-3 mb-lg-4" :class="{'col-lg-3': !toggle, 'col-lg-4': toggle}" :item="item"/>
                  </template>
                </div>
              </div>
              <div v-if="query.tab === 'DOCUMENT'">
                <div v-if="loading" class="list-cards row mt-3 mt-lg-4">
                  <SkeletonDocumentItem v-for="ind in 4" :key="ind" class="col-12 mb-3 mb-lg-4" :class="{'col-3': !toggle, 'col-4': toggle}" />
                </div>
                <div v-else class="list-cards row mt-3 mt-lg-4">
                  <DocumentItem v-for="(item, ind) in listData" :key="ind" class="col-12 mb-3 mb-lg-4" :class="{'col-lg-3': !toggle, 'col-lg-4': toggle}" :item="item"/>
                </div>
              </div>
              <div v-if="query.tab === 'EXAM'">
                <div v-if="loading" class="list-cards row mt-3 mt-lg-4">
                  <SkeletonQuizItem v-for="ind in 4" :key="ind" class="col-12 mb-3 mb-lg-4" :class="{'col-3': !toggle, 'col-4': toggle}" />
                </div>
                <div v-else class="list-cards row mt-3 mt-lg-4">
                  <QuizItem v-for="(item, ind) in listData" :key="ind" class="col-12 mb-3 mb-lg-4" :class="{'col-3': !toggle, 'col-4': toggle}" :item="item"/>
                </div>
              </div>
              <Pagination :page="query.page" :per-page="query.limit" :total="query.total" align="right" @change="loadData" />
              <NoData v-if="!loading && !listData.length" type="video" text="Không có dữ liệu" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Cookies from 'js-cookie'
import MenuLeft from '~/components/library-v2/MenuLeft.vue'
import FormSearch from '~/components/library-v2/FormSearch.vue'
import TopicItem from '~/components/library-v2/topic/item.vue'
import VideoItem from '~/components/library-v2/video/item.vue'
import DocumentItem from '~/components/library-v2/document/item.vue'
import QuizItem from '~/components/library-v2/quiz/item.vue'
import SkeletonTopicItem from '~/components/skeleton/topic/item.vue'
import SkeletonDocumentItem from '~/components/skeleton/document/item.vue'
import SkeletonQuizItem from '~/components/skeleton/quiz/item.vue'
import Pagination from '~/components/library-v2/Pagination.vue'
import NoData from '~/components/nodata/new.vue'
import Resource from '~/common/api/resource'
const searchResource = new Resource('library/search-library')
export default {
  components: {
    MenuLeft,
    FormSearch,
    TopicItem,
    VideoItem,
    DocumentItem,
    QuizItem,
    SkeletonTopicItem,
    SkeletonDocumentItem,
    SkeletonQuizItem,
    Pagination,
    NoData
  },
  layout: 'thu-vien',
  asyncData ({ isDev, route, store, env, params, req, res, redirect, error }) {
    const token = store.state.token
    const query = {
      page: 1,
      limit: 12,
      total: 0,
      keyword: '',
      book_id: '',
      category_id: '',
      tab: 'TOPIC',
      type: 'topic'
    }

    return {
      toggle: false,
      loading: false,
      query,
      token,
      listData: []
    }
  },
  watch: {
    $route (newValue, oldValue) {
      this.loadData()
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    toggleFilter () {
      this.toggle = !this.toggle
    },
    async loadData (page = 1) {
      if (this.loading) { return }
      this.query.page = page
      this.query.keyword = this.$route.query.keyword ? decodeURIComponent(this.$route.query.keyword) : ''
      this.query.category_id = this.$route.query?.category_id || null
      this.query.class_id = Cookies.get('interestedClass') || this.user?.id || ''
      this.query.book_id = this.$route.query?.book_id || null
      this.query.tab = this.$route.query?.tab || 'TOPIC'
      this.query.type = this.$route.query?.type || 'doc'
      if (this.query.type === 'pdf') {
        this.query.type = 'doc'
      }
      this.loading = true
      const { data, total } = await searchResource.list(this.query, this.token)
      this.listData = data
      this.query.total = total
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.lib-inner-section {
  padding-top: 3rem;
  padding-bottom: 8rem;
  @media (max-width: 1366px) {}
  @media (max-width: 1200px) {}
  @media (max-width: 992px) {}
  @media (max-width: 768px) {}
  @media (max-width: 576px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .data-divider {
    height: 1px;
    background-color: #e6e6e6;
  }
  .data-block-counter{
    font-size: 1rem;
    line-height: 1.3;
    margin-bottom: .875rem;
    @media (max-width: 1366px) {}
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {}
  }
  .btn-advance-toggle-filter {
    z-index: 1000;
    top: 1.5rem;
    left: 1rem;
    gap: 1rem;
    @media (max-width: 1366px) {}
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {
      top: 1rem;
      left: .5rem;
      gap: .5rem;
    }
  }
  .list-data {
    .data-block {
      border-radius: 8px;
    }
  }
}
</style>
